const primary = {
  primary: '#FFA333'
};

const secondary = {
  secondary: '#0E4059',
  'secondary-lighten-1': '#3C6478',
  'secondary-lighten-2': '#6A8897',
  'secondary-lighten-3': '#99ADB7',
  'secondary-lighten-4': '#C7D1D6',
  'secondary-lighten-5': '#F5F5F5'
};

const tertiary = {
  tertiary: '#42535b'
};

// Status Color
const statusColors = {
  requested: {
    requested: '#CFE3FF', // base color
    'requested-lighten-5': '#fdfdff',
    'requested-lighten-4': '#fafcff',
    'requested-lighten-3': '#f5f8ff',
    'requested-lighten-2': '#ebefff',
    'requested-lighten-1': '#dfe9ff',
    'requested-darken-1': '#b1c8e6',
    'requested-darken-2': '#97b1cc',
    'requested-darken-3': '#8099b3',
    'requested-darken-4': '#6b8199',
    'requested-darken-5': '#596d80'
  },
  scheduled: {
    scheduled: '#005AD8',
    'scheduled-lighten-5': '#E3EFFF',
    'scheduled-lighten-4': '#CFE3FF',
    'scheduled-lighten-3': '#AACBFA',
    'scheduled-lighten-2': '#84B5FA',
    'scheduled-lighten-1': '#6298E4',
    'scheduled-darken-1': '#0A60D8',
    'scheduled-darken-2': '#004099',
    'scheduled-darken-3': '#002559',
    'scheduled-darken-4': '#001C42'
  },
  arrived: {
    arrived: '#4D0087',
    'arrived-lighten-5': '#F6EBFF',
    'arrived-lighten-4': '#EBCFFF',
    'arrived-lighten-3': '#D8ACFA',
    'arrived-lighten-2': '#BA8CDE',
    'arrived-lighten-1': '#A173C7',
    'arrived-darken-1': '#762DAD',
    'arrived-darken-2': '#58118F',
    'arrived-darken-3': '#380261',
    'arrived-darken-4': '#23003D'
  },
  inProgress: {
    inprogress: '#FFD34D',
    'inprogress-lighten-5': '#FFF8E3',
    'inprogress-lighten-4': '#FFF1C7',
    'inprogress-lighten-3': '#FFE699',
    'inprogress-lighten-2': '#FFD863',
    'inprogress-lighten-1': '#FCBE03',
    'inprogress-darken-1': '#BB8D00',
    'inprogress-darken-2': '#9C7500',
    'inprogress-darken-3': '#7A5C00',
    'inprogress-darken-4': '#503C00'
  },
  completed: {
    completed: '#007531',
    'completed-lighten-5': '#E4FFEF',
    'completed-lighten-4': '#C9FFE0',
    'completed-lighten-3': '#ABFACC',
    'completed-lighten-2': '#82E0A9',
    'completed-lighten-1': '#65C28C',
    'completed-darken-1': '#338254',
    'completed-darken-2': '#00662B',
    'completed-darken-3': '#004D20',
    'completed-darken-4': '#003B18'
  },
  cancelled: {
    cancelled: '#B8B8B8',
    'cancelled-lighten-5': '#F5F5F5',
    'cancelled-lighten-4': '#E0E0E0',
    'cancelled-lighten-3': '#D1D1D1',
    'cancelled-lighten-2': '#BDBDBD',
    'cancelled-lighten-1': '#A8A8A8',
    'cancelled-darken-1': '#737373',
    'cancelled-darken-2': '#5E5E5E',
    'cancelled-darken-3': '#404040',
    'cancelled-darken-4': '#333333'
  },
  noShow: {
    noshow: '#A50028',
    'noshow-lighten-5': '#FFEDF1',
    'noshow-lighten-4': '#FFD4DE',
    'noshow-lighten-3': '#FDB6C7',
    'noshow-lighten-2': '#FF94AD',
    'noshow-lighten-1': '#F26687',
    'noshow-darken-1': '#AB2041',
    'noshow-darken-2': '#8A0726',
    'noshow-darken-3': '#540014',
    'noshow-darken-4': '#3B000E'
  }
};

export { primary, secondary, tertiary, statusColors };

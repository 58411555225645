export default {
  registerRoutes(routes, newRoutes) {
    newRoutes.forEach(mRoutes => {
      let registeredRoutePaths = routes.map(route => route.name);
      let newRoutesToRegister = mRoutes.filter(routeToRegister => {
        if (!registeredRoutePaths.includes(routeToRegister.name)) {
          return routeToRegister;
        }
      });
      if (newRoutesToRegister) {
        routes = [...routes, ...newRoutesToRegister];
      }
    });

    return routes;
  },
  /**
   * Open to any better way to do this, but this is needed in order to prevent weird blips of components rendered based on authentication
   * i.e. Not authenticated when app is initially rendered, then authentication finishes causes the org warning to flash on the screen on load
   */
  setAppLoadedTrue(store) {
    if (!store.getters['Auth/appLoaded']) {
      store.commit('Auth/setAppLoaded', true);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async checkDirty(store, from) {
    if (store.$app.config.globalProperties.checkDirtyConfirmation && from.meta.isDirty) {
      if (!(await store.$app.config.globalProperties.checkDirtyConfirmation())) {
        return true;
      }
    }

    return false;
  },
  isAuthRequired(route) {
    return route.meta.requiresAuth || typeof route.meta.requiresAuth === 'undefined';
  },
  async isUserAuthorized(store, getOrg = true) {
    const token = sessionStorage?.getItem('access_token') || localStorage?.getItem('access_token');
    let user = await store.getters['Auth/me'];
    let authorized = token && user;

    if (!authorized && token) {
      try {
        authorized = await store.dispatch('Auth/getMe', getOrg).then(r => r.data.id);
      } catch (e) {
        authorized = false;
      }
    }

    return authorized;
  },
  setPageTitle(store, to, appName) {
    if (!to.meta.preventTitleReset) {
      store.commit('navigation/setPageTitle', null);
    }
    document.title = to.meta.title ? `${to.meta.title} - ${appName}` : appName;
  },
  isDisabledForUserRole(store, route) {
    const currentUser = store?.state?.Auth?.me;
    // We can't block pages if we don't have user context, but we should always have it
    if (!currentUser) {
      return false;
    }

    if (
      route.meta?.maxRole &&
      store.$app.config.globalProperties.novaCore.isUserRoleGreater(
        currentUser.role,
        route.meta.maxRole
      )
    ) {
      return true;
    }

    if (
      route.meta?.minRole &&
      store.$app.config.globalProperties.novaCore.isUserRoleLess(
        currentUser.role,
        route.meta.minRole
      )
    ) {
      return true;
    }

    return false;
  },
  isDisabledForWarehouseUser(store, route) {
    const currentUser = store?.state?.Auth?.me;

    if (!currentUser) {
      return false;
    }
    if (route.meta.isWarehouseRestricted) {
      console.log(route);
    }

    return false;
  },
  isDisabledForUser(store, route) {
    return (
      this.isDisabledForUserRole(store, route) || this.isDisabledForWarehouseUser(store, route)
    );
  },
  getHomepageNameForUser(store) {
    return store.$app.config.globalProperties.novaCore.isInternalUser(store?.state?.Auth?.me)
      ? 'internalHome'
      : 'home';
  }
};

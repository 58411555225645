import { createFeatureFlagPlugin } from '@loadsmart/vue-feature-flags';

import config from '@/config/config';

export function getAuthHeader() {
  const key = 'access_token';
  const token = sessionStorage?.getItem(key) || localStorage?.getItem(key);
  return `Bearer ${token || ''}`;
}

function customFetch(url, init) {
  init = init || {};
  init.headers = init.headers || {};
  init.headers.Authorization = getAuthHeader();
  init.headers['X-UI-AppName'] = config.ui_app_name;
  return fetch(url, init);
}

export default createFeatureFlagPlugin({
  config: {
    url: config.unleash_url,
    appName: config.unleash_app_name,
    clientKey: getAuthHeader(),
    fetch: customFetch,
    disableMetrics: true,
    usePOSTrequests: true,
    environment: config.server_env === 'production' ? 'production' : 'development'
  }
});

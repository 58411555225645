<template>
  <button-base :test-id="testId" variant="tertiary" v-bind="{ ...$attrs }">
    <!-- @slot Button Content -->
    <slot></slot>
  </button-base>
</template>

<script>
import { ButtonBase } from '@/components';
import testable from '@/components/mixins/testable';

/**
 * Button Base with hard-coded "tertiary" variant
 * @displayName Tertiary Button
 */
export default {
  name: 'TertiaryButton',
  components: {
    ButtonBase
  },
  mixins: [testable]
};
</script>

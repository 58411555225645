<template>
  <button-base
    variant="primary"
    :test-id="testId"
    v-bind="{ ...$attrs, ...$props }"
    color="color-text-inverted">
    <!-- @slot Inner button content -->
    <slot></slot>
  </button-base>
</template>

<script>
import { ButtonBase } from '@/components';
import testable from '@/components/mixins/testable';

/**
 * Button Base with hard-coded "primary" variant and "color-text-inverted" color token
 * @displayName Primary Button
 */
export default {
  name: 'PrimaryButton',
  components: {
    ButtonBase
  },
  mixins: [testable]
};
</script>

import navigation from './modules/navigation';
import Websockets from './modules/websockets';
import app from './modules/app';
import satelliteApp from '@satellite/store/app';
import { createStore } from 'vuex';

// Import all modules from the modules folder
async function loadLocalStores() {
  const context = import.meta.glob('@/modules/**/store.js');
  const modules = await Promise.all(
    Object.keys(context).map(async key => {
      const module = await context[key]();
      return module.default;
    })
  );
  return modules;
}

function joinStores(existingStore, newStore) {
  let store = {};
  Object.keys(existingStore).map(storeKey => {
    store[storeKey] = { ...existingStore[storeKey], ...newStore[storeKey] };
  });

  return store;
}

async function initStore() {
  const modules = {};
  const loadedModules = await loadLocalStores();

  loadedModules.forEach(module => {
    if (!modules[module.name]) {
      modules[module.name] = module;
    }
  });

  if (!modules.navigation) {
    modules.navigation = navigation;
  }
  if (!modules.App) {
    modules.App = joinStores(satelliteApp, app);
  }
  if (!modules.Websockets) {
    modules.Websockets = Websockets;
  }

  const store = new createStore({
    modules: modules
  });

  // Properties on the Vuex store
  store.modules = () => modules;

  return store;
}

export { initStore };

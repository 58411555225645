// Vue
import { createApp } from 'vue';
// APP
import App from './App.vue';
import config from './config/config';
import { initStore } from './store';

// Mixins
import localStorageMixin from '@satellite/components/mixins/localStorageMixin';
import utilityMixin from '@/components/mixins/utilityMixin';
import mappedStates from './components/mixins/mappedStates';
import mappedGetters from './components/mixins/mappedGetters';
import MixpanelPlugin from '@satellite/plugins/MixpanelPlugin.js';

// Plugins
import util from '@/plugins/util';
import mirandaUtil from '@/plugins/MirandaUtil';
import ConfirmDialogPlugin from '@satellite/plugins/ConfirmDialog/ConfirmDialogPlugin';
import AxiosPlugin from '@satellite/plugins/AxiosPlugin';
import { loadFonts } from '@/plugins/webfontloader';
import vuetify from './plugins/vuetifyPlugin';
import validation from '@satellite/plugins/Validator';
import initSentry from '@satellite/plugins/sentry';
import Notifications from '@kyvg/vue3-notification';
import velocity from 'velocity-animate';
// import Hotjar from 'vue-hotjar';
import featureFlagPlugin from './plugins/featureFlagPlugin';
import initializeRouter from '@/routes.js';

// Deps
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import moment from 'moment-timezone';
import * as Core from '@nova/core';
import '@loadsmart/miranda-wc';
import mitt from 'mitt';
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';

// Helpers
import SocketPlugin from '@satellite/plugins/SocketPlugin';

import warehouseService from '@satellite/services/warehouse-service';
import userService from '@satellite/services/user-service';
import dockService from '@satellite/services/dock-service';
import companyService from '@satellite/services/company-service';
import loadtypeService from '@satellite/services/loadtype-service';
import appointmentService from '@satellite/services/appointment-service';
import carrierService from '@satellite/services/carrier-service';

const app = createApp(App);

// Init Plugins
window.momentjs = moment;
AxiosPlugin.init(null, 'Luna', app);
ConfirmDialogPlugin.init(app);
loadFonts();
ConfirmDialogPlugin.init(app);

const emitter = mitt();
emitter.$on = emitter.on;
emitter.$off = emitter.off;
emitter.$emit = emitter.emit;

// MODULES
// Vue.use(VueMask);
app.use(Notifications, { velocity });
app.use(featureFlagPlugin);
app.use(VueTelInput);

// Vue Prototypes
app.config.globalProperties.mirandaUtil = mirandaUtil;
app.config.globalProperties.util = util;
app.config.globalProperties.novaCore = Core;
app.config.globalProperties.$validator = validation;
app.config.globalProperties.config = config;
app.config.globalProperties.$globalLoading = false;

app.config.globalProperties.notify = utilityMixin.methods.notify;

// Services
app.config.globalProperties.services = {
  warehouse: warehouseService,
  user: userService,
  dock: dockService,
  company: companyService,
  loadtype: loadtypeService,
  appointment: appointmentService,
  carrier: carrierService
};

// Vue Global Mixins
app.mixin(utilityMixin);
app.mixin(localStorageMixin);
app.mixin(mappedStates);
app.mixin(mappedGetters);

app.config.globalProperties.mixpanel = new MixpanelPlugin();
app.config.globalProperties.mixpanel.init();

initStore().then(async store => {
  app.use(store);
  app.config.globalProperties.sockets = new SocketPlugin(app);
  store.$app = app;

  /**
   * Create a bound notify function with access to novaCore,
   * so we can use it in the store, like so: `store.notify('message', 'success')`.
   */
  store.notify = (message, type = 'success', options = {}) => {
    utilityMixin.methods.notify.call(
      {
        novaCore: Core,
        $notify: app.config.globalProperties.$notify
      },
      message,
      type,
      options
    );
  };

  const routes = await initializeRouter(store);
  app.use(routes);
  app.config.globalProperties.$eventHub = emitter;

  initSentry(app, routes, config);

  store.commit('Settings/setSettingsMetadata', Core.EntitySettings);
  await store.dispatch('Auth/setInitialAccessToken');
  store.commit('Auth/setPreviewWarehouseId', sessionStorage.getItem('previewWarehouseId'));

  // Import and use vuetify
  app.use(vuetify);
  app.mount('#app');
});

export default app;

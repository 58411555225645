import '@/styles/main.scss';

import { createVuetify } from 'vuetify';
import { satelliteGlobalThemeColors, satelliteOpts } from '@satellite/plugins/vuetify';

export const globalThemeColors = {
  ...satelliteGlobalThemeColors
};

const opts = {
  ...satelliteOpts
};

export default createVuetify(opts, { customProperties: true });

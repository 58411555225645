import { deepEqual } from '@satellite/../nova/core';

/* @vue/component */
export default {
  /**
   * IMPORTANT:
   * The Validatable.js mixin was taken and slimmed down from Vuetify so we could carry over our existing validation system.
   * This file may contain unused methods, computed properties, etc. that we should cut out as we discover more about
   * what we need from this mixin
   * @ignore
   */

  name: 'VValidatable',

  props: {
    /**
     * Field name
     */
    fieldName: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Is field disabled
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Field validation rules - see Validator.vue
     */
    rules: {
      type: Array,
      default: () => []
    },
    /**
     * Validate the field on blur
     */
    validateOnBlur: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Iterator to programmatically validate field
     */
    validationIterator: {
      type: Number,
      required: false,
      default: 0
    },
    /**
     * Field error string - will display along with error bucket errors
     */
    externalError: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Allows display of field messages - errors/hints
     */
    shouldDisplayMessages: {
      type: Boolean,
      required: false,
      default: true
    },
    shouldValidate: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      errorBucket: [],
      hasFocused: false,
      hasInput: false,
      isFocused: false,
      lazyValue: this.modelValue,
      valid: false,
      blurCount: 0,
      focusCount: 0
    };
  },

  computed: {
    hasError() {
      return this.errorBucket.length > 0;
    },
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        this.lazyValue = val;

        /**
         * Emit change when internal form value updates
         * @event update:ModelValue
         * @property val
         */
        this.$emit('update:modelValue', val);
      }
    },
    isDisabled() {
      return this.disabled || (!!this.form && this.form.disabled);
    },
    computedValidateOnBlur() {
      return this.validateOnBlur;
    }
  },

  watch: {
    rules: {
      handler(newVal, oldVal) {
        if (deepEqual(newVal, oldVal)) return;
        this.validate();
      },
      deep: true
    },
    internalValue() {
      // If it's the first time we're setting input,
      // mark it with hasInput
      this.hasInput = true;
      this.computedValidateOnBlur || this.$nextTick(this.validate);
    },
    isFocused(isFocused) {
      if (!isFocused && !this.isDisabled) {
        this.hasFocused = true;
        this.computedValidateOnBlur && this.$nextTick(this.validate);
      }
    },
    hasError(val) {
      if (this.shouldValidate) {
        /**
         * Emits when the hasError computed property changes
         * @event update:error
         * @property {boolean} hasError
         */
        this.$emit('update:error', val);
      }
    },
    modelValue(val) {
      this.lazyValue = val;
    },
    validationIterator() {
      this.validate(true, this.modelValue);
    }
  },

  methods: {
    /**
     * @public
     * Sets the field to focused so it can validate
     */
    handleFocus() {
      this.focusCount++;
      this.isFocused = true;
    },
    /**
     * @public
     * Sets the field to not focused so it can validate
     */
    handleBlur() {
      this.blurCount++;
      this.isFocused = false;
    },
    /**
     * @public
     * @param {boolean} force
     * @param value
     * @returns {boolean} this.valid
     */
    validate(force = false, value, additionalRules = []) {
      if (
        force ||
        this.shouldValidate ||
        (this.computedValidateOnBlur && this.focusCount > 0 && this.blurCount > 0)
      ) {
        this.$emit('clear-external-error', this.fieldName);
        let errorBucket = [];
        value = value || this.internalValue;

        if (force) this.hasInput = this.hasFocused = true;

        errorBucket = this.util.validateValuesAgainstRules(value, [
          ...this.rules,
          ...additionalRules
        ]);
        if (this.externalError) {
          errorBucket.push(this.externalError);
        }
        this.errorBucket = [...new Set(errorBucket)];
        this.valid = errorBucket.length === 0;
      }
      return this.valid;
    }
  }
};

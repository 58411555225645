import MixpanelEvents from '@/enums/MixpanelEvents';
import config from '@/config/config';
import mixpanel from 'mixpanel-browser';

class MixpanelPlugin {
  constructor() {
    this.track = this.trackMixpanelEvent.bind(this);
    this.init = this.initMixpanel.bind(this);
    this.events = MixpanelEvents;
    this.api = mixpanel;
    this.identify = this.setMixpanelIdentity.bind(this);
    this.reset = this.resetMixpanel.bind(this);
    this.isInitialized = false;
    this.getEntryPoint = this.getEntryPoint.bind(this);
    this.isDev = config.server_env === 'development';
  }

  trackMixpanelEvent(event, data = {}) {
    if (this.isDev) {
      console.log('Tracking Mixpanel Event Debug:\n', { event, data });
    }

    // Silently ignore events if no token is set
    if (!config.mixpanel_token) {
      return;
    }

    try {
      mixpanel.track(event, data);
    } catch (e) {
      console.log('MP Event Error', e);
    }
  }

  initMixpanel() {
    if (!config.mixpanel_token) {
      return;
    }

    this.isInitialized = true;
    mixpanel.init(config.mixpanel_token, { debug: false });
  }

  setMixpanelIdentity(me) {
    if (!config.mixpanel_token || !me) {
      return;
    }

    mixpanel.identify(me.email);
    mixpanel.people.set({
      $name: `${me.firstName} ${me.lastName}`,
      $email: me.email,
      'Nova User ID': me.id,
      'User Role': me.role,
      'Org ID': me.orgId
    });

    mixpanel.get_group('Org ID', me.orgId).set({
      Name: me.orgName,
      Type: me.orgType,
      'Created At': me.orgCreateDateTime,
      'TC Confirmed At': me.tcConfirmedAt
    });
  }

  resetMixpanel() {
    if (this.isInitialized) {
      mixpanel.reset();
    }
  }

  getEntryPoint(vueInstance, mappedEntryPoints) {
    for (let mappedEntryPoint of mappedEntryPoints) {
      let parent = vueInstance.$parent;
      while (parent) {
        if (parent.$options.name === mappedEntryPoint.component) {
          return mappedEntryPoint.entryPoint;
        }
        parent = parent.$parent;
      }
    }

    return 'Untraceable';
  }
}

export default MixpanelPlugin;

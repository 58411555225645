import get from 'lodash/get';
import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';
import { PREVIEW_TAG, searchStringToQuery } from '@nova/core';

/**
 * @typedef {Object} AutocompleteOptions
 * @property {string} [search]
 * @property {number} [page]
 * @property {number} [limit]
 * @property {boolean} [useSearchExpanded]
 */

/**
 * @typedef {Object} AutocompleteItem
 * @property {string} id - The unique identifier of the company.
 * @property {boolean} isActive - Indicates whether the company is active.
 * @property {string[]} [tags] - The tags associated with the company, or null if none.
 * @property {string} name - The name of the company.
 * @property {string} [scac] - The SCAC code of the company.
 * @property {string} [mc] - The MC number of the company, or null if not available.
 * @property {string} [usdot] - The USDOT number of the company, or null if not available.
 * @property {string} type - The type of the company.
 */

/**
 * @typedef {Object} AutocompleteResult
 * @property {AutocompleteItem[]} items - The companies which matched the search.
 * @property {number} count - The number of items in the current page
 * @property {number} total - The total number of companies which match the search.
 */

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

const service = {
  async getCompanyById(companyId, params = {}, options = {}, requestConfig = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(
      `company/${companyId}?${queryString}`,
      {
        params
      },
      requestConfig
    );
    return getResponse(response, options);
  },
  async getCompanies(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`company?${queryString}`, { params });
    return getResponse(response, options);
  },
  async createCompany(data, options = {}) {
    const response = await axios.post(`company`, data);
    return getResponse(response, options);
  },
  async getPreviewCompany() {
    const companies = await this.getCompanies({
      s: {
        tags: {
          $cont: PREVIEW_TAG
        }
      }
    });
    return companies?.[0] ?? null;
  },
  /**
   * Get autocompletion for companies.
   * @param {AutocompleteOptions} [options={}] - The optional parameters for autocompletion.
   * @returns {Promise<AutocompleteResult>}
   */
  async autocomplete(options = {}) {
    const defaultLimit = 15;
    const { limit, page, search, useSearchExpanded } = {
      limit: defaultLimit,
      page: 1,
      search: '',
      ...options
    };

    async function withoutSearchExpanded() {
      const query = searchStringToQuery(search, {
        searchFieldsToSplit: ['name'],
        searchableFields: ['name', 'scac', 'mc', 'usdot']
      });
      const payload = { limit, page, s: { $or: query } };
      try {
        const response = await service.getCompanies(payload, { includeMetaData: true });
        const items = get(response, 'data.data', []);
        const total = get(response, 'data.total', 0);
        const count = get(items, 'length', 0);
        return { items, count, total };
      } catch (err) {
        return { items: [], count: 0, total: 0 };
      }
    }

    async function withSearchExpanded() {
      const offset = (page - 1) * limit;
      const payload = { search, pagination: { limit, offset } };
      try {
        const response = await axios.post('/company-search/autocomplete', payload);
        const items = get(response, 'data.items', []);
        const total = get(response, 'data.meta.totalRecords', 0);
        const count = get(items, 'length', 0);
        return { items, count, total };
      } catch (err) {
        return { items: [], count: 0, total: 0 };
      }
    }

    return useSearchExpanded ? withSearchExpanded() : withoutSearchExpanded();
  }
};

export default service;

<template>
  <button-base variant="warning" :test-id="testId" v-bind="{ ...$attrs }">
    <!-- @slot Inner button content -->
    <slot></slot>
  </button-base>
</template>

<script>
import { ButtonBase } from '@/components';
import testable from '@/components/mixins/testable';

/**
 * Button Base with hard-coded "warning" variant
 * @displayName Warning Button
 */
export default {
  name: 'WarningButton',
  components: {
    ButtonBase
  },
  mixins: [testable]
};
</script>

import config from '@/config/config';
import routeHelpers from '@satellite/helpers/routes';
import { isCarrierUser } from '@satellite/../nova/core';
import { createRouter, createWebHistory } from 'vue-router';

function addCatchToRouterFunc(router, funcName) {
  const originalFunc = router[funcName];
  router[funcName] = async function (...args) {
    try {
      const result = await originalFunc.apply(this, args);
      return result;
    } catch (e) {
      onError(e);
    }
  };
}

function onError(e) {
  if (
    e.name !== 'NavigationDuplicated' &&
    !e.message?.toLowerCase()?.includes('navigation cancelled')
  ) {
    throw e;
  }
}

// Routes are located in the individual module folders
// Import all of the module routes files.
async function loadLocalRoutes() {
  const context = import.meta.glob('@/modules/**/routes.js');
  const routes = await Promise.all(
    Object.keys(context).map(async key => {
      const module = await context[key]();
      return module.default;
    })
  );
  return routes;
}

async function initializeRouter(store) {
  let routes = [
    // Moduleless routes can go here
    {
      path: '/',
      name: 'home',
      redirect: { name: 'warehouse.select' },
      meta: {
        requiresAuth: false
      }
    }
  ];

  const moduleRoutes = await loadLocalRoutes();
  routes = routeHelpers.registerRoutes(routes, moduleRoutes);

  const router = createRouter({
    history: createWebHistory(),
    routes: routes
  });

  addCatchToRouterFunc(router, 'push');
  addCatchToRouterFunc(router, 'replace');

  router.beforeEach(async (to, from, next) => {
    if (!store.getters['App/s3BaseUrl']) {
      await store.dispatch('App/getAppEnvironment', {}, { root: true });
    }
    /**
     * We needed a way to log in a user from the warehouse portal for preview mode
     */
    const token = to.query?.token;
    if (token && token !== 'undefined') {
      await store.dispatch('Auth/loginAs', token, 'loginPageWithTokenParam');
      const parsedNextRoute = to.query?.nextRoute ? JSON.parse(to.query?.nextRoute) : null;
      const nextRoute = parsedNextRoute ?? { name: 'book' };

      const warehouseId = parsedNextRoute?.params?.warehouseId;
      if (warehouseId) {
        await store.commit('Auth/setPreviewWarehouseId', warehouseId);
      }

      // Ensure this handles if the user doesn't actually authenticate
      return next(nextRoute);
    }

    // Check if the from route is dirty (has unsaved data), if it is then ask user if they want to proceed
    if (await routeHelpers.checkDirty(store, from)) {
      return;
    }

    routeHelpers.setPageTitle(store, to, config.application_name);

    const authRequired = routeHelpers.isAuthRequired(to);

    if (!authRequired) {
      routeHelpers.setAppLoadedTrue(store);
      return next();
    }

    const authenticatedUser = await store.dispatch('Auth/getAuthenticatedUser');
    if (authenticatedUser) {
      if (!isCarrierUser(authenticatedUser)) {
        await store.dispatch('Auth/logInToWarehousePortal');
        return next();
      }
      // Force redirect to company registration for auth pages if they don't have one already
      if (!authenticatedUser.companyId && to.name !== 'register.company') {
        next({ name: 'register.company' });
      }
      return next();
    } else {
      routeHelpers.setAppLoadedTrue(store);
      next({ name: 'login', query: { redirectPath: window.location.pathname } });
    }
  });

  return router;
}

export default initializeRouter;

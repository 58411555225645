import axios from 'axios';
import config from '@/config/config';
import notificationsHelper from '@satellite/helpers/notifications';
import * as core from '@nova/core';
import { isObject, isUUID } from 'class-validator';
import { LAST_CRUD_ACTION_KEY } from '@satellite/plugins/util';

// TODO: Refactor so it lives in Vue instance rather than window?
class AxiosPlugin {
  static init(redirect401, uiApplicationName, app) {
    window.axios = axios;
    const axiosInstance = window.axios;
    axiosInstance.defaults.baseURL = config.neutron_url;
    axiosInstance.defaults.headers['X-UI-AppName'] = uiApplicationName;
    axiosInstance.defaults.headers['X-UI-Version'] = import.meta.env.VITE_NOVA_VERSION;

    axiosInstance.defaults.paramsSerializer = params =>
      Object.keys(params)
        .filter(key => params[key] !== undefined)
        .map(key => {
          if (isObject(params[key])) {
            params[key] = JSON.stringify(params[key]);
          }
          if (Array.isArray(params[key])) {
            return params[key].map(v => `${key}[]=${encodeURIComponent(v)}`).join('&');
          }
          return `${key}=${encodeURIComponent(params[key])}`;
        })
        .join('&');

    axiosInstance.interceptors.request.use(config => {
      // app.$validator.clear();
      app.config.globalProperties.$globalLoading = true;
      return config;
    });

    // Response Interception
    axiosInstance.interceptors.response.use(
      response => {
        if (isObject(response?.data)) {
          const { entity, action, data } = response.data;

          const formattedEntityName = notificationsHelper.getEntityTerm(response.data);
          const crudAction = core.databaseActions[action]?.pastTense;

          // Global notification for succeeded WRITE actions
          if (formattedEntityName && crudAction && !response.config?.suppressNotification) {
            let message = `${formattedEntityName} has been ${crudAction}`;
            app.config.globalProperties.notify(message, 'success');
          }

          // Store the last operation at session storage
          // So we can ignore the subspace message to avoid double requests
          if (isUUID(data?.id) && ['delete', 'create', 'update'].includes(action)) {
            sessionStorage.setItem(LAST_CRUD_ACTION_KEY, `${action}-${entity}:${data?.id}`);
          }
        }
        app.config.globalProperties.$globalLoading = false;
        return response;
      },
      error => {
        app.config.globalProperties.$globalLoading = false;

        // The request has returned no response, we have no context
        const hasErrorMessage = Boolean(error.response?.data?.message);
        let errorMessage = 'A network error occurred. If the issue persists please contact us.';

        if (hasErrorMessage) {
          errorMessage = Array.isArray(error.response.data.message)
            ? error.response.data.message.join(', ')
            : error.response.data.message;
        }
        errorMessage = app.config.globalProperties.novaCore.upperFirst(errorMessage);
        // app.config.globalProperties.$validator.clear();
        if (error.response?.status === 401) {
          if (redirect401) {
            const url = window.location.href;
            const path = new URL(url).pathname;
            app.config.globalProperties.$store.dispatch('Auth/logout');
            if (path !== redirect401) {
              window.location.replace(
                `${redirect401}?${window.location.pathname}&errorMessage=${errorMessage}`
              );
              return;
            }
          }
        }

        if (!error.config?.suppressNotification) {
          app.config.globalProperties.notify(errorMessage, 'error', { duration: -1 });
        }

        return Promise.reject(error);
      }
    );
  }

  static setAccessToken(token) {
    window.axios.defaults.headers.Authorization = token ? `Bearer ${token}` : undefined;
  }
}

export default AxiosPlugin;

import VuetifyConfirm from './index';
import vuetify from '@/plugins/vuetifyPlugin';

class ConfirmDialogPlugin {
  static init(app) {
    app.use(VuetifyConfirm, {
      vuetify,
      buttonTrueText: 'Confirm',
      buttonFalseText: 'Nevermind',
      color: 'info',
      icon: 'mdi-help-circle-outline',
      title: 'Confirm',
      width: 600,
      property: '$confirm'
    });
  }
}

export default ConfirmDialogPlugin;

<template>
  <button-base variant="icon" v-bind="{ ...$attrs, ...$props }" :test-id="testId">
    <basic-icon :size="iconSize ?? $attrs.size" :disabled="$attrs.disabled" :color="iconColor">
      <!-- @slot MDI icon to display. Can optionally omit 'mdi-' prefix from icon e.g. "mdi-email" or "email" will both work -->
      <slot></slot>
    </basic-icon>
  </button-base>
</template>

<script>
import BasicIcon from '@/components/elements/basic/BasicIcon.vue';
import ButtonBase from '@/components/elements/buttons/ButtonBase';
import testable from '@/components/mixins/testable';

/**
 * Button Base with hard-coded "icon" variant
 * @displayName Icon Button
 */
export default {
  name: 'IconButton',
  mixins: [testable],
  components: { ButtonBase, BasicIcon },
  props: {
    /**
     * Size of the icon - can be miranda font size token, small/default/large, or explicit value/unit e.g. 20px, 10rem
     * If not provided, the button's "size" prop will be used
     */
    iconSize: {
      type: String,
      required: false
    },
    iconColor: {
      type: String,
      required: false,
      default: 'color-text-primary'
    }
  }
};
</script>

<template>
  <m-field
    class="is__relative"
    :class="{ 'flex-1': !width }"
    :status="hasError ? 'danger' : 'default'">
    <m-field-label v-if="label">
      {{ label }}

      <vuetify-tooltip v-if="hint" bottom>
        <template #activator="{ props }">
          <basic-icon v-bind="props" class="ml-1" size="16px" color="color-text-tertiary">
            mdi-help-circle
          </basic-icon>
        </template>
        <span>{{ hint }}</span>
      </vuetify-tooltip>
    </m-field-label>
    <vuetify-menu
      transition="scale-transition"
      :close-on-content-click="false"
      v-model="showDatePicker"
      :content-class="`date-picker-wrapper date-picker-wrapper-${$.uid}`"
      attach="#date-picker-dialog"
      bottom
      right
      offset-y
      offset-overflow>
      <template #activator="{ attrs }">
        <icon-button
          ref="trigger"
          v-if="iconActivated"
          test-id="date-picker-trigger"
          size="small"
          icon-size="16px"
          v-bind="attrs"
          @click="showDatePicker = true">
          calendar-blank
        </icon-button>
        <text-field
          v-else
          ref="trigger"
          :style="computedWidth"
          class="date-picker-trigger"
          test-id="date-picker-field"
          v-bind="attrs"
          @click="showDatePicker = true"
          :placeholder="'Select a date'"
          :model-value="textFieldPlaceholder"
          color="blue"
          readonly
          :trailing-icon="{ icon: 'chevron-down' }"></text-field>
        <m-field-hint :data-testid="`${testId}-errors`" v-if="shouldDisplayMessages">
          {{ errorBucket.join(', ') }}
        </m-field-hint>
      </template>

      <vuetify-date-picker
        hide-header
        ref="datePicker"
        :min="formattedMinDate"
        :max="formattedMaxDate"
        :color="computedColor"
        prev-icon="mdi-chevron-up"
        next-icon="mdi-chevron-down"
        :model-value="localValue"
        @update:model-value="handleDateClick"></vuetify-date-picker>
    </vuetify-menu>
  </m-field>
</template>

<script>
import { VDatePicker as VuetifyDatePicker } from 'vuetify/lib/components/VDatePicker/VDatePicker.mjs';
import { VTooltip as VuetifyTooltip } from 'vuetify/lib/components/VTooltip/VTooltip.mjs';
import { BasicIcon, IconButton, TextField } from '@/components';
import { VMenu as VuetifyMenu } from 'vuetify/lib/components/VMenu/VMenu.mjs';
import { LuxonDateTimeFormats, formatDateTimeWithMilitarySupport } from '@nova/core';
import { DateTime } from 'luxon';
import fieldMixin from '@/components/mixins/fieldMixin';
import validatable from '@/components/mixins/validatable';
import testable from '@/components/mixins/testable';
import { isISO8601 } from 'class-validator';

export default {
  // TODO: Input does not allow readonly mode, so using disabled to prevent manual input.
  // TODO: This could be confusing to users.  MIRANDA NEEDS READONLY FIELDS!
  components: { IconButton, VuetifyDatePicker, VuetifyMenu, TextField, VuetifyTooltip, BasicIcon },
  mixins: [fieldMixin, validatable, testable],
  props: {
    /**
     * @model
     * Format LuxonDateTimeFormats.DateDashed
     */
    modelValue: {
      type: String,
      required: false
    },
    /**
     * The earliest date that can be selected - leave null to keep it open ended
     * Format LuxonDateTimeFormats.DateDashed
     */
    minDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * The latest date that can be selected - leave null to keep it open ended
     * Format LuxonDateTimeFormats.DateDashed
     */
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Use the calendar Icon button to activate datepicker rather than text input
     */
    iconActivated: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Format of date to display in the text field.  Use LuxonDateTimeFormats
     */
    displayFormat: {
      type: String,
      required: false,
      default: LuxonDateTimeFormats.MonthDayYearSlashed
    },
    /**
     * Field label
     */
    label: {
      type: String,
      required: false,
      default: null
    },
    timezone: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    computedColor() {
      return this.mirandaUtil.getTokenCssValue('color-text-primary');
    },
    textFieldPlaceholder() {
      return this.modelValue
        ? DateTime.fromISO(this.modelValue).setZone(this.timezone).toFormat(this.displayFormat)
        : null;
    },
    formattedMinDate() {
      let formattedMinDate = this.minDate;
      if (formattedMinDate && isISO8601(formattedMinDate)) {
        formattedMinDate = formatDateTimeWithMilitarySupport(
          this.minDate,
          this.timezone,
          LuxonDateTimeFormats.DateDashed,
          false,
          LuxonDateTimeFormats.DateDashed
        );
      }
      return formattedMinDate;
    },
    formattedMaxDate() {
      let formattedMaxDate = this.maxDate;
      if (formattedMaxDate && isISO8601(formattedMaxDate)) {
        formattedMaxDate = formatDateTimeWithMilitarySupport(
          this.maxDate,
          this.timezone,
          LuxonDateTimeFormats.DateDashed,
          false,
          LuxonDateTimeFormats.DateDashed
        );
      }
      return formattedMaxDate;
    },
    localValue() {
      return DateTime.fromISO(this.modelValue, { zone: 'utc' }).toJSDate() ?? null;
    }
  },
  data() {
    return {
      showDatePicker: false,
      selectedDate: null,
      datePickerWrapperObserver: null,
      initialDialogAttrs: null
    };
  },
  methods: {
    handleDateClick(date) {
      this.internalValue = DateTime.fromJSDate(date, LuxonDateTimeFormats.DateDashed, {
        zone: 'utc'
      }).toISO();
      this.showDatePicker = false;
    },
    attachDatePicker() {
      if (this.showDatePicker) {
        const datePickerDialog = document.getElementById('date-picker-dialog');
        this.$nextTick(() => {
          this.util.attachElToEl({
            el: this.$refs.trigger.$el,
            elToAttach: datePickerDialog,
            elToAttachWidth: 290
          });
          datePickerDialog.showModal();
        });
      }
    }
  },
  watch: {
    showDatePicker(newVal) {
      if (newVal) {
        this.attachDatePicker();
      } else {
        const datePickerDialog = document.getElementById('date-picker-dialog');
        datePickerDialog.close();
      }
    }
  },
  created() {
    window.addEventListener('resize', this.attachDatePicker);
  },
  destroyed() {
    window.removeEventListener('resize', this.attachDatePicker);
  }
};
</script>

<style lang="scss" scoped>
.date-picker-wrapper {
  padding-top: $m-spacing-2;
  padding-bottom: $m-spacing-4;
  background-color: #fff;
  max-width: 100%;
  box-shadow: none;
}

::v-deep {
  .v-btn--active {
    background-color: $m-color-text-primary;
    color: $m-color-text-inverted !important;

    .v-btn__content {
      &:before {
        content: ' ';
        height: 1px;
        background-color: white;
        width: 10px;
        position: absolute;
        bottom: -2px;
      }
    }
  }
}

.date-picker-trigger {
  @media (max-width: $tabletBreakpoint) {
    width: 100% !important;
  }
}
</style>

<template>
  <notifications
    :ignore-duplicates="true"
    animation-type="velocity"
    :position="position"
    v-bind="{ ...$attrs, ...$props }">
    <template #body="{ item, close }">
      <div class="app-notification" :class="item.data.appClasses">
        <basic-icon :color="item.data.iconColor" size="2rem" class="type-icon">
          {{ item.data.icon }}
        </basic-icon>
        <div class="flex-1">
          <m-stack gap="spacing-1">
            <m-text variant="heading-md" v-if="item.title">
              <strong>{{ item.title }}</strong>
            </m-text>
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <m-text variant="body-md" v-html="item.text"></m-text>
          </m-stack>
        </div>

        <component
          v-if="item.data.actionFn"
          :is="item.data.actionComponent"
          :test-id="getTestId(item, 'action-btn')"
          @click="item.data.actionFn">
          {{ item.data.actionLabel ?? 'test' }}
        </component>

        <button class="close" @click="close" :data-testId="getTestId(item, 'close-btn')">
          <basic-icon size="20px">mdi-close</basic-icon>
        </button>
      </div>
    </template>
  </notifications>
</template>

<script>
import { BasicIcon, ErrorButton, TertiaryButton, WarningButton, PrimaryButton } from '@/components';
import { toKebabCase } from '@satellite/../nova/core';

/**
 * App snackbar-style notifications that wraps the Vue Notification lib.
 *
 * For options and other info, check out the `notify` method inside utilityMixinBase.js in Satellite
 * @displayName App Notification
 */
export default {
  name: 'AppNotification',
  components: { BasicIcon, ErrorButton, PrimaryButton, WarningButton, TertiaryButton },
  props: {
    /**
     * Position of the snackbar
     */
    position: {
      type: String,
      required: false,
      default: 'bottom right'
    }
  },
  methods: {
    getTestId(item, postFix) {
      return `${toKebabCase(item.text ?? item.title)}-${postFix}`;
    }
  }
};
</script>

<style lang="scss">
#app-notifications {
  .vue-notification-group {
    width: 500px !important;
    max-width: calc(100% - 2rem);
  }

  .vue-notification-wrapper {
    height: auto !important;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .app-notification {
    border-radius: 5px;
    position: relative;
    padding: 0.5rem 1rem;
    display: flex;

    i.type-icon {
      color: $white !important;
    }

    &.app-success {
      background-color: $white !important;
      border: 1px solid rgba(var(--v-theme-success)) !important;
      border-left: 40px solid rgba(var(--v-theme-success)) !important;
      color: $black;
    }

    &.app-error {
      background-color: $white !important;
      border: 1px solid rgba(var(--v-theme-error)) !important;
      border-left: 40px solid rgba(var(--v-theme-error)) !important;
      color: $black;
    }

    &.app-warning {
      background-color: $white !important;
      border: 1px solid $color-warning-100 !important;
      border-left: 40px solid $color-warning-100 !important;
      color: $black;
    }

    &.app-info {
      background-color: $white !important;
      border: 1px solid $color-accent-60 !important;
      border-left: 40px solid $color-accent-60 !important;
      color: $black;
    }

    .app-title {
      font-size: 1rem !important;
      margin-bottom: 0;
      line-height: 1.25rem;
    }

    i.type-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      margin-left: -35px;
      font-size: 2rem;
      z-index: 15;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 0.5rem;

      i {
        font-size: 12px;
      }
    }
  }
}
</style>

// Vite module resolution doesn't work properly in this file.  Using full path instead of export alias
// TODO: Investigate why!
import { VBtn } from 'vuetify/lib/components/VBtn/VBtn.mjs';
import { primary, secondary, tertiary, statusColors } from '@satellite/plugins/vuetify-colors.js';

export const satelliteGlobalThemeColors = {
  // Original top-level colors
  ...primary,
  ...secondary,
  ...tertiary,
  accent: '#005A87',
  error: '#f24848',
  disabled: '#F5F5F5',
  disabledgrey: '#f5f5f5',
  chalkblue: '#F7FBFC',
  chalkorange: '#fcefdc',
  mobileblue: '#002638',
  mobilebluesecondary: '#015A86',
  reserve: '#3d545c',

  ...statusColors.requested,
  ...statusColors.scheduled,
  ...statusColors.arrived,
  ...statusColors.inProgress,
  ...statusColors.completed,
  ...statusColors.cancelled,
  ...statusColors.noShow
};

export const satelliteOpts = {
  aliases: {
    PrimaryButton: VBtn,
    SecondaryButton: VBtn,
    OutlineButton: VBtn,
    TextButton: VBtn,
    EditButton: VBtn
  },
  defaults: {
    PrimaryButton: { color: 'primary' },
    SecondaryButton: { color: 'secondary' },
    OutlineButton: { variant: 'outlined' },
    TextButton: { variant: 'text' },
    EditButton: { color: 'secondary', text: 'Edit', prependIcon: 'mdi-pencil' },
    VMenu: { VListItem: { VBtn: { size: 'small', variant: 'text', block: true, flat: true } } },
    VProgressLinear: { height: 6, color: 'primary', rounded: true, indeterminate: true },
    VSelect: { listProps: { slim: true }, variant: 'underlined', persistentPlaceholder: true },
    VTextField: { variant: 'underlined', density: 'compact', persistentPlaceholder: true },
    VAutocomplete: { variant: 'underlined', persistentPlaceholder: true },
    VCombobox: { variant: 'underlined', persistentPlaceholder: true },
    VRadio: { color: 'primary' },
    VRadioGroup: { color: 'primary' },
    VCheckbox: { color: 'primary' },
    VChip: { variant: 'flat' },
    VSwitch: { color: 'primary' }
  },
  theme: {
    defaultTheme: 'opendockTheme',
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      opendockTheme: {
        colors: satelliteGlobalThemeColors
      }
    },
    variations: {
      colors: ['disabled', 'tertiary'],
      lighten: 5,
      darken: 5
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
};

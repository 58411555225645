import { createApp, h } from 'vue';
import Confirm from '@satellite/components/elements/Confirm';

function Install(app, options = {}) {
  const property = options.property || '$confirm';
  delete options.property;
  const vuetify = options.vuetify;
  delete options.vuetify;
  if (!vuetify) {
    console.warn(
      'Module vuetify-confirm needs vuetify instance. Use Vue.use(VuetifyConfirm, { vuetify })'
    );
  }

  function createDialogCmp(options) {
    const container = document.querySelector('[data-app=true]') || document.body;
    return new Promise(resolve => {
      const vnode = h(Confirm, {
        ...options,
        onResult: value => {
          resolve(value);
          appInstance.unmount();
          container.removeChild(el);
        }
      });
      const appInstance = createApp(vnode);
      appInstance.use(vuetify);
      const el = document.createElement('div');
      container.appendChild(el);
      appInstance.mount(el);
    });
  }

  function show(message, options = {}) {
    options.message = message;
    return createDialogCmp(options);
  }

  app.config.globalProperties[property] = show;
  app.config.globalProperties[property].options = options || {};
}

export default Install;

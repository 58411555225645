<template>
  <button
    class="sidebar-button"
    :class="{ active: isActive }"
    v-bind="{ ...$attrs, ...$props }"
    :data-testid="testId"
    :style="`background-color:${computedColor};`">
    <basic-icon v-if="leadingIcon" class="m-r-2" size="20px" :color="iconColor">
      {{ leadingIcon }}
    </basic-icon>
    <!-- @slot Button content -->
    <slot></slot>
    <basic-icon v-if="trailingIcon" class="m-l-2" size="20px" :color="iconColor">
      {{ trailingIcon }}
    </basic-icon>
  </button>
</template>

<script>
import testable from '@/components/mixins/testable';
import { BasicIcon } from '@/components';

/**
 * Button for specific use inside the m-sidebar Miranda component
 * @displayName Sidebar Button
 */
export default {
  name: 'SidebarButton',
  mixins: [testable],
  components: { BasicIcon },
  props: {
    /**
     * Button color - takes miranda color tokens
     */
    color: {
      type: String,
      required: false,
      default: 'transparent'
    },
    /**
     * Icon color - takes miranda color tokens
     */
    iconColor: {
      type: String,
      required: false
    },
    /**
     * Leading icon - mdi icon that can optionally leave out the 'mdi-' prefix
     */
    leadingIcon: {
      type: String,
      required: false
    },
    /**
     * Trialing icon - mdi icon that can optionally leave out the 'mdi-' prefix
     */
    trailingIcon: {
      type: String,
      required: false
    },
    isActive: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    computedColor() {
      return this.mirandaUtil.getTokenCssValue(this.color) ?? this.color;
    }
  }
};
</script>

<style scoped lang="scss">
/**
Gross - but since Miranda does not have this component yet, we copied base button styles for consistency
 */
.sidebar-button {
  color: $m-color-text-inverted;
  border: none;
  transition: color 0.35s ease-in-out 0s, background-color 0.35s ease-in-out 0s,
    border-color 0.35s ease-in-out 0s;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: var(--m-font-family-default, 'Manrope', sans-serif);
  line-height: var(--m-line-height-1, 100%);
  font-weight: var(--m-font-weight-bold, 700);
  font-size: var(--m-button-font-size);
  cursor: pointer;
  display: flex;
  block-size: 100%;
  inline-size: 100%;
  padding-inline: var(--m-button-padding-x);
  padding-block: var(--m-button-padding-y);
  border-radius: var(--m-button-border-radius);
  --m-button-background-color: transparent;
  --m-button-base-size: var(--m-global-height-default, 2.5rem);
  --m-button-border-color: rgba(var(--m-color-neutral-90, 61, 74, 85), 1);
  --m-button-border-radius: var(--m-border-radius-s, 4px);
  --m-button-color: rgba(var(--m-color-text-secondary, 61, 74, 85), 1);
  --m-button-outline-color: rgba(var(--m-color-primary-100, 0, 51, 51), 1);
  --m-button-outline-offset: 2px;
  --m-button-padding-x: var(--m-spacing-2, 8px);
  --m-button-padding-y: var(--m-spacing-3, 12px);
  --m-button-font-size: var(--m-font-size-3, 0.875rem);

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    background-color: $m-color-primary-20 !important;
    opacity: 0.1;
  }

  &.active {
    background-color: $m-color-primary-60 !important;
  }
}
</style>

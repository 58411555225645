// Load .env vars into the config

/**
 * @typedef LunaEnvVars
 * @type {object}
 * @property {string} application_name
 * @property {string} unleash_url
 * @property {string} unleash_app_name
 * @property {string} unleash_client_key
 * @property {string} server_env
 */

/** @type {LunaEnvVars} */
const formattedVars = {};

for (const [key, value] of Object.entries(import.meta.env)) {
  formattedVars[key.replace('VITE_', '').toLowerCase()] = value;
}

/**
 * @returns {string}
 */
function getUnleashUrl(env) {
  const { unleash_url, neutron_url } = env;
  const url = unleash_url ? unleash_url.trim() : null;
  if (url) return url;
  return new URL('/feature-flag/proxy', neutron_url).toString();
}

/**
 * @returns {string}
 */
function getUnleashClientKey(env) {
  const { unleash_client_key } = env;
  const key = unleash_client_key ? unleash_client_key.trim() : null;
  if (key) return key;
  const token =
    (sessionStorage?.getItem('access_token') || localStorage?.getItem('access_token')) ?? '';
  return `Bearer ${token || ''}`;
}

const config = {
  ...formattedVars,
  ...{
    // Add app config/constants here that you do not want in the .env files
    application_name: 'Opendock Scheduling',
    unleash_url: getUnleashUrl(formattedVars),
    unleash_client_key: getUnleashClientKey(formattedVars)
  }
};

export default config;

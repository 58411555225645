import * as Sentry from '@sentry/vue';
import pick from 'lodash/pick';

/** @typedef {import('../../../core/src/user').IUser} IUser */

/**
 * Set user for Sentry context
 * @param {IUser|null} user
 */
export function setUser(user) {
  const data = !user?.id ? null : pick(user, 'id', 'email', 'role', 'orgId', 'companyId');
  Sentry.setUser(data);
}

export default function initSentry(app, routes, config) {
  if (!config.sentry_dsn) {
    return;
  }

  Sentry.init({
    app,
    environment: config.server_env,
    dsn: config.sentry_dsn,
    release: config.nova_version,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Navigation cancelled from',
      'AxiosError',
      'NotAllowedError'
    ],
    integrations: [
      Sentry.vueIntegration({ tracingOptions: { trackComponents: true } }),
      Sentry.browserTracingIntegration({ router: routes, routeLabel: 'path' })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.sentry_traces_sample_rate ?? 1,
    // Redact sensitive information
    beforeSend(event) {
      function redactSensitiveData(str) {
        if (!str) {
          return str;
        }

        return str.replace(
          /[a-zA-Z0-9_=]{36}\.[a-zA-Z0-9_=]{51}\.[a-zA-Z0-9_\-+/=]{43}/u,
          'JWT_REDACTED'
        );
      }

      // Ignore Axios Errors for non internal server errors
      if (event.request?.statusCode >= 400 && event.request?.statusCode < 500) {
        return null;
      }

      if (event.request) {
        event.request.url = redactSensitiveData(event.request.url);
      }

      if (event.exception) {
        event.exception.values = event.exception.values?.map(exception => ({
          ...exception,
          value: redactSensitiveData(exception.value)
        }));
      }

      return event;
    }
  });
}

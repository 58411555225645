<template>
  <div class="is__relative">
    <div :class="`date-picker-${$.uid}`"></div>
    <basic-icon
      size="35"
      class="m-l-2 close-icon"
      @click="toggleDatePicker(true)"
      v-if="!useCustomTrigger">
      calendar
    </basic-icon>
    <vuetify-menu
      :attach="`.date-picker-${$.uid}`"
      transition="scale-transition"
      :close-on-content-click="false"
      v-model="showDatePicker"
      content-class="date-picker-wrapper"
      bottom
      right
      offset-y
      offset-overflow
      min-width="auto">
      <template #activator="{ on, attrs }">
        <icon-button
          v-if="iconActivated"
          test-id="date-picker-trigger"
          size="small"
          icon-size="16px"
          v-bind="attrs"
          v-on="on">
          calendar-blank
        </icon-button>
      </template>
      <vuetify-date-picker
        no-title
        ref="datePicker"
        multiple="range"
        hide-header
        :color="computedColor"
        prebasic-icon="mdi-chevron-up"
        next-icon="mdi-chevron-down"
        :model-value="selectedDates"
        @update:model-value="setDates"></vuetify-date-picker>
    </vuetify-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';
import { VDatePicker as VuetifyDatePicker } from 'vuetify/lib/components/VDatePicker/VDatePicker.mjs';
import { VMenu as VuetifyMenu } from 'vuetify/lib/components/VMenu/VMenu.mjs';

import { LuxonDateTimeFormats } from '@nova/core';

import mirandaUtil from '@/plugins/MirandaUtil';
import { IconButton, BasicIcon } from '@/components';

export default {
  // TODO: Input does not allow readonly mode, so using disabled to prevent manual input.
  // TODO: This could be confusing to users.  MIRANDA NEEDS READONLY FIELDS!
  components: { BasicIcon, IconButton, VuetifyDatePicker, VuetifyMenu },
  props: {
    /**
     * @model
     * Format LuxonDateTimeFormats.DateDashed
     */
    modelValue: {
      type: Array,
      required: false,
      default: () => [new Date(), new Date()]
    },
    /**
     * The earliest date that can be selected - leave null to keep it open ended
     * Format LuxonDateTimeFormats.DateDashed
     */
    minDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * The latest date that can be selected - leave null to keep it open ended
     * Format LuxonDateTimeFormats.DateDashed
     */
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Use the calendar Icon button to activate datepicker rather than text input
     */
    iconActivated: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Format of date to display in the text field.  Use LuxonDateTimeFormats
     */
    displayFormat: {
      type: String,
      required: false,
      default: LuxonDateTimeFormats.MonthDayYearSlashed
    },
    /**
     * Field label
     */
    label: {
      type: String,
      required: false,
      default: null
    },
    timezone: {
      type: String,
      required: false,
      default: null
    },
    useCustomTrigger: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const showDatePicker = ref(false);
    const selectedDates = ref([]);
    const computedColor = computed(() => mirandaUtil.getTokenCssValue('color-text-primary'));
    const toggleDatePicker = value => (showDatePicker.value = value);
    const setDates = value => {
      selectedDates.value = value;
      if ((value?.length ?? 0) > 1) {
        emit('update:model-value', [value[0], value[value.length - 1]]);
        toggleDatePicker(false);
      }
    };

    watch(selectedDates, (value, oldValue) => {
      if (oldValue?.length === 1 && value?.length === 0) {
        const start = DateTime.fromJSDate(oldValue[0]).startOf('day').toJSDate();
        const end = DateTime.fromJSDate(oldValue[0]).endOf('day').toJSDate();
        setDates([start, end]);
      }
    });

    return { showDatePicker, selectedDates, toggleDatePicker, setDates, computedColor };
  }
};
</script>

<style lang="scss" scoped>
.date-picker-wrapper {
  padding-top: $m-spacing-2;
  padding-bottom: $m-spacing-4;
  background-color: #fff;
  max-width: max-content;
}

::v-deep {
  .v-btn--active {
    background-color: $m-color-text-primary;
    color: $m-color-text-inverted !important;

    .v-btn__content {
      &:before {
        content: ' ';
        height: 1px;
        background-color: white;
        width: 10px;
        position: absolute;
        bottom: -2px;
      }
    }
  }
  #date-picker-field {
    display: none;
  }
}
</style>
